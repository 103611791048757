import { sprintf } from 'sprintf-js'
import date from 'date-and-time'

export function formatTime(value: number, precision = 0): string {
	if (value == null || isNaN(value)) {
		return ''
	}
	let hours = 0
	let minutes = Math.floor(value / 60)
	if (minutes > 59) {
		hours = Math.floor(minutes / 60)
		minutes = minutes % 60
	}
	const seconds = value % 60
	return hours
		? sprintf(`%'0.0i:%'02i:%'0${2 + (precision > 0 ? precision + 1 : 0)}.${precision}f`, hours, minutes, seconds)
		: sprintf(`%'0.0i:%'0${2 + (precision > 0 ? precision + 1 : 0)}.${precision}f`, minutes, seconds)
}
export function getTime(d: Date) {
	if (d) {
		const zone = d ? d.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
		return date.format(d, 'h:mm A', false) + ' ' + zone
	}
	return ''
}

export function localAbbreviatedTimezone() {
	const d = new Date()
	return d.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
}
export function localTimezone() {
	const d = new Date()
	return d.toLocaleTimeString('en-us', { timeZoneName: 'long' }).split(' ')[2]
}
