











import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ConfigState } from 'shared/state'

import { displayType } from 'shared/util'

@Component
export default class RemoteId extends Vue {

	@Prop() value!: string
	@Prop() label!: string
	@Prop() config!: ConfigState

	remoteId: string = ''

	@Watch('value', { immediate: true })
	valueChange(newValue) {
		this.remoteId = newValue
	}

	setId() {
		this.config.setRemoteId(this.remoteId, displayType(this.$route.name!))
	}

}
