
import { Component, Vue } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'
import codes from 'iso-language-codes'
import { Erg } from 'shared/state/Erg'

export const defaultLanguages = [{
	label: 'English',
	value: 'en-US'
}]
export const languageCodes = codes

@Component
export class LanguageModule extends Vue {
	private supportedLanguages: any[] = defaultLanguages

	get list(): any {
		this.supportedLanguages = defaultLanguages
		Erg.supportedLanguages.forEach( f => {
			const n = f.split('.', 1)[0].toString()
			const c = codes.filter(cd => cd.iso639_1 === n)
			if(c.length) {
				this.supportedLanguages.push({label:c[0].name, value: n})
			}
		})
		return this.supportedLanguages
	}
}

export const Languages = new LanguageModule()
