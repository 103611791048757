




import { Vue, Component } from 'vue-property-decorator'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

@Component({ components: { ScaleLoader } })
export default class Spinner extends Vue {}
