import { Vue, Component, Watch } from 'vue-property-decorator'
import { merge } from 'lodash'
import { ServerModule } from 'shared/state'
import { Collection } from 'shared/types'
import {
	Machine, RaceState, RaceDefinition, RaceStatus, RaceStatusData,
	RaceData, RaceResults, RaceDataSplit, MachineType
} from 'shared/types/erg'
import { makeComputedBinding, sortByKeyAsc, sortByKeyDesc } from 'shared/util'
import { initialCap, formatTime } from 'shared/util'
import { ServerModulePen } from './ServerPen'

@Component
export class ErgModule extends Vue {

	status: RaceState = 'inactive'
	lanes: RaceStatusData[] = []
	definition: RaceDefinition | null = null
	machines: Machine[] = []
	paceboats: Collection<Machine> = {}
	paceraceLockScore: boolean = true
	classes: string[] = []
	time = 0
	bufferedTime = 0
	totalElapsed = 0
	numFinished = 0
	supportedThemes: string[] = []
	supportedLanguages: string[] = []
	paceraceFinished: boolean  = false
	paceraceBufferedFinish: boolean = false
	isTimeCapRace: boolean = false

	private server: ServerModule | ServerModulePen | null = null
	private subscription: number | null = null

	attachToServer(server: ServerModule | ServerModulePen) {
		this.server = server
	}

	get unit() {
		if(!this.definition ||
			this.definition.duration_type === 'calories' ||
			this.definition.race_type.includes('calorie')) {
			return 'calories'
		}
		return 'meters'
	}

	get isPaceRace(): boolean {
		return this.definition ? this.definition.boats.filter(b => b.is_paceboat).length > 0 : false
	}

	raceResults() {
		const result = localStorage.getItem('raceResults')
		if (result) { return JSON.parse(result) }
		return this.machines
			.filter(m => !m.is_paceboat)
			.map(m => {

				const avg_pace = (this.unit === 'calories')
					? m.avg_calories_per_hour || ''
					: m.avg_pace ? formatTime(m.avg_pace, 1) : ''

				const row = {
					Place: m.position,
					Lane: m.lane,
					Name: m.name,
					'Athlete ID': m.affiliation,
					'Average Pace': avg_pace,
					[initialCap(this.unit)]:  Math.floor(Erg.definition!.duration_type === 'time' ?  m[this.unit]!
												: Erg.definition!.duration - m[this.unit]!).toFixed(0),
					Score: m.score
				}
				if(Erg.classes.length) {
					row['Place in Class'] = m.position_in_class,
					row.Class = m.class_name
				}
				return row
			})
	}

	private supported_languages(languageFiles: string[]) {
		this.supportedLanguages = languageFiles
	}

	private supported_themes(themeFiles: string[]) {
		this.supportedThemes = themeFiles
	}

	private race_definition(definition: RaceDefinition) {
		if(definition.duration_type === 'time') {
			definition.duration /= 100
			definition.split_value /= 100
		}

		let startTime = 0
		this.isTimeCapRace = false
		if(definition.time_cap && definition.time_cap > 0) {
			this.isTimeCapRace = true
			startTime = definition.time_cap
		}
		if(definition.duration_type === 'time') {
			startTime = definition.duration
		}
		this.bufferedTime = this.time = startTime

		this.totalElapsed = 0
		this.numFinished = 0
		this.paceraceFinished = this.paceraceBufferedFinish = false

		const machines: Machine[] = []
		const classes: Set<string> = new Set()
		definition.boats
			.filter(boat => boat.name !== 'EMPTY')
			.forEach((boat, i) => {
				classes.add(boat.class_name)
				const machine: Machine = {
					is_paceboat: boat.is_paceboat ? true : false,
					lane: boat.lane_number,
					affiliation: boat.affiliation,
					name: boat.name,
					class_name: boat.class_name,
					position: i + 1,
					position_in_class: i + 1,
					meters: 0,
					calories: 0,
					gap: 0,
					meters_behind: 0,
					calories_behind: 0,
					pace: 0,
					avg_pace: 0,
					calories_per_hour: 0,
					avg_calories_per_hour: 0,
					spm: 0,
					splits: [],
					time: 0,
					score: null,
					active_machine: boat.machine_type || 'unknown',
					erg_status: '1',
					disconnected: 0,
					finished: false,
					overtaken: false
				}
				machines[boat.lane_number - 1] = machine
				if(machine.is_paceboat) {
					this.paceboats[machine.class_name] = machine
				}
			})
		this.machines = machines
		this.classes = Array.from(classes)

		this.definition = definition
		this.$emit('race_definition', definition)
	}

	private race_data(data: RaceData) {
		if(this.isPaceRace && this.paceraceLockScore && this.paceraceFinished) { return }
		if(!this.definition) { return }
		const raceType = this.definition.duration_type

		data.time /= 100
		const elapsed = raceType === 'time'
			? this.time - data.time
			: this.isTimeCapRace ? data.time - (this.definition.time_cap! - this.time) : data.time - this.time
		this.totalElapsed += elapsed
		this.bufferedTime = this.time
		this.time = this.isTimeCapRace ? this.definition.time_cap! - data.time : data.time
		if(this.time < 0) {
			this.time = 0
		}

		data.data.forEach(item => {
			const machine = this.machines[item.lane - 1]
			if(machine === undefined) { return }
			if (item.meters) {
				item.meters /= 10
			}
			if (item.calories) {
				item.calories /= 10
			}
			if(this.definition!.duration_type === 'meters') {
				item.meters = this.definition!.duration - item.meters!
				if(!machine.finished && item.meters >= this.definition!.duration) {
					machine.finished = true
					this.numFinished++
				}
			}
			if(this.definition!.duration_type === 'calories') {
				item.calories = this.definition!.duration - item.calories!
				if(!machine.finished && item.calories >= this.definition!.duration) {
					machine.finished = true
					this.numFinished++
				}
			}
			if(!machine.finished && this.definition!.duration_type && data.time === 0) {
				machine.finished = true
				this.numFinished++
			}

			item.pace = item.pace !== undefined ? item.pace / 100 : 0
			item.avg_pace = item.avg_pace !== undefined ? item.avg_pace / 100 : 0
			item.calories_per_hour = item.calories_per_hour || 0
			item.avg_calories_per_hour = item.avg_calories_per_hour || 0
			item.gap = item.gap !== undefined ? item.gap / 10 : 0
			item.meters_behind = item.meters_behind !== undefined ? item.meters_behind / 10 : 0
			item.calories_behind = item.calories_behind !== undefined ? item.calories_behind / 10 : 0
			// time is sent in 100ths/sec, but we only care about 10ths. also matches format sent by race_results
			item.time = item.time !== undefined ? Math.round(item.time / 10) / 10 : 0
			item.splits = item.splits !== undefined
				? item.splits.map(s => ({
					split_time: s.split_time / 100,
					split_distance: s.split_distance ? s.split_distance / 10 : 0,
					split_pace: calculateSplitPace(s, machine.active_machine),
					split_calorie: s.split_calorie ? s.split_calorie / 10 : 0
				}))
				: []
			if(machine.is_paceboat) {
				item.position_in_class = 0
			}

			if(this.paceraceLockScore && machine.overtaken) {
				machine.position_in_class = item.position_in_class - 1
			}

			if(!this.paceraceLockScore && machine.overtaken) {
				// handle setting being turned off mid race
				machine.overtaken = false
				if(machine.finished) {
					machine.finished = false
					machine.score = null
					this.numFinished--
				}
			}

			if(!(this.paceraceLockScore && machine.overtaken)) {
				merge(machine, item)
				machine.disconnected = machine.erg_status === '0' ? machine.disconnected + 1 : 0
			}
		})

		// After all distances have been updated, check whether paceboats have overtaken anything
		if(this.isPaceRace && this.paceraceLockScore) {
			// calculate all position numbers client side
			const sorted = this.machines.slice().sort((a, b) => {
				return sortByKeyDesc(a, b, this.unit, sortByKeyAsc(a, b, 'lane'))
			})
			let position = 1
			const classPositions: Collection<number> = {}

			sorted.forEach(machine => {
				if(machine.is_paceboat) {
					machine.position = machine.position_in_class = 0
					return
				}

				if(!classPositions[machine.class_name]) { classPositions[machine.class_name] = 1 }
				machine.position = position++
				machine.position_in_class = classPositions[machine.class_name]++

				if(machine.overtaken) { return }

				const paceboat = this.paceboats[machine.class_name]
				if(!paceboat) { return }
				if(paceboat[this.unit]! > machine[this.unit]!) {
					machine.overtaken = true
					machine.finished = true
					machine.score = Math.round(machine.time / 10)
					this.numFinished++
				}
			})
			this.paceraceFinished = this.paceraceBufferedFinish
			if(this.paceraceFinished) {
				setTimeout( _ => {
					this.pause()
				}, 1000)
			}
			this.paceraceBufferedFinish = this.numFinished + Object.keys(this.paceboats).length >= this.definition.boats.length
		}

		this.$emit('race_data', this.machines, elapsed)

	}

	private race_status(status: RaceStatus) {
		if(this.status === status.state_desc && this.lanes === status.data) { return }
		if(status.state_desc === 'inactive') {
			this.definition = null
		}
		if(status.state_desc === 'warmup') { localStorage.removeItem('raceResults') }
		this.status = status.state_desc
		if(status.data) { this.lanes = status.data }
		if (status.state_desc === 'final results') {
			// set the buffered time after delaying one second.

			setTimeout( _ => {
				this.bufferedTime = this.time
				if(this.isPaceRace) {
					this.paceraceFinished = this.paceraceBufferedFinish = true
				}
			}, 1000)
		}
		this.$emit('race_status', this.status)
	}

	private race_results(results: RaceResults) {
		results.data.forEach(r => {
			const machine = this.machines[r.lane - 1]
			if(!machine) { return }
			if(this.paceraceLockScore && machine.overtaken) { return }
			if(this.isTimeCapRace && r.other_score !== undefined && r.other_score < this.definition!.duration) {
				machine.other_score = r.other_score
			}
			machine.score = r.score
			if(this.definition!.duration_type !== 'time') {
				machine.time = r.score / 10
			}
			if(!machine.finished && machine.score) {
				machine.finished = true
				this.numFinished++
			}
		})
		localStorage.setItem('raceResults', JSON.stringify(this.raceResults()))
		this.$emit('race_results', results)
	}

	private pause() {
		this.$emit('pause')
	}

	private get serverState() { return this.server ? this.server.state : 'unconnected' }

	@Watch('serverState')
	private serverStateChange() {
		// the server might reconnect after its first connection, but we only want to add our listener once
		if(this.serverState === 'connected' && this.subscription == null) {
			this.subscription = this.server!.addListener((channel, packet) => {
				if(this[channel] && typeof this[channel] === 'function') {
					this[channel](packet)
				}
			})
		}
		if(this.serverState !== 'connected') {
			this.definition = null
			this.status = 'inactive'
		}
	}

}

function calculateSplitPace(split: RaceDataSplit, type: MachineType) {
	const time = Math.floor(split.split_time / 10)
	const distance = Math.floor(split.split_distance ? split.split_distance / 10 : 0)
	const pace_distance = type === 'bike' ? 1000 : 500
	return distance === 0 ? 0 : Math.floor(pace_distance * time / distance) / 10
}

export const Erg = new ErgModule()
export const ErgState = makeComputedBinding(Erg)
