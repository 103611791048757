












import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { ConfigState, Erg } from 'shared/state'
import { ConfigModule, ConfigField, ConfigControl, Collection } from 'shared/types'
import { slugify } from 'shared/util'
import * as configModules from 'shared/config'

import PanelDisplay from './ConfigPanel.vue'

interface ConfigPanel {
	label: string
	fields: ConfigControl[]
}

@Component({ components: { PanelDisplay } })
export default class Config extends Vue {

	@Prop() config!: ConfigState
	@Prop({ default: '' }) page!: string
	@Prop({ default: false }) loggedIn!: boolean

	nav = ''

	@Watch('panels', { immediate: true })
	panelsChanged() {
		if(!this.nav) {
			this.nav = this.panels.length ? this.panels[0].label : ''
		}
	}

	get duringRace() {
		return Erg.status === 'race running'
	}

	get className() {
		const classes = [
			'config',
			slugify(this.nav),
			this.page + '-config'
		]
		if(this.loggedIn) { classes.push('loggedIn') }
		if(this.duringRace) { classes.push('duringRace') }
		return classes.join(' ')
	}

	get panels(): ConfigPanel[] {
		const panels: Collection<ConfigPanel> = {}
		const groups: Collection<ConfigControl> = {}

		Object.keys(configModules).forEach(modName => {
			const mod = configModules[modName] as ConfigModule<any>
			if(mod.page && mod.page !== this.page) { return }

			Object.keys(mod.fields).forEach(fieldName => {
				const field = mod.fields[fieldName]
				if(!this.shouldBeVisible(field)) { return }

				const panelName = field.panel || mod.panel
				if(!panels[panelName]) {
					panels[panelName] = {
						label: panelName,
						fields: []
					}
				}

				const control = { ...field.control!	}
				if(!control.name) {
					control.name = `${modName}.${fieldName}`
				}

				if(field.group) {
					if(!groups[field.group]) {
						groups[field.group] = {
							component: 'controlGroup',
							label: field.group,
							options: [],
							name: panelName
						}
					}
					groups[field.group].options.push(control)
					return
				}

				panels[panelName].fields.push(control)
			})
		})

		for(const groupName of Object.keys(groups)) {
			const group = groups[groupName]
			panels[group.name!].fields.push(group)
			group.name = slugify(groupName)
		}

		return Object.keys(panels)
			.filter(name => panels[name].fields.length)
			.map(name => panels[name])
	}

	private shouldBeVisible(field: ConfigField<any>) {
		return field.control &&
			(!field.private || this.loggedIn) &&
			(!field.hideDuringRace || !this.duringRace)
	}

}
