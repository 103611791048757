import { get } from 'lodash'

export const sortByKeyAsc = (a: any, b: any, key: string, secondarySort: number = 0): number => {
	const ak = get(a, key)
	const bk = get(b, key)
	return (ak > bk && 1) || (ak < bk && -1) || secondarySort
}

export const sortByKeyDesc = (a: any, b: any, key: string, secondarySort: number = 0): number => {
	const ak = get(a, key)
	const bk = get(b, key)
	return (ak < bk && 1) || (ak > bk && -1) || secondarySort
}
