import { Vue, Component } from 'vue-property-decorator'

import { defaultLanguages } from './languages'

@Component
class TranslationState extends Vue {

	locale = ''
	strings: { [key: string]: { [key: string]: string } } = {}

	setLang(locale: string) {
		if(!this.strings[locale] && locale !== 'en-US') {
			return fetch(`/config/lang/${locale}.json`)
				.then(res => res.json())
				.then(json => {
					this.$set(this.strings, locale, json)
					this.locale = locale
				})
		} else {
			this.locale = locale
			return Promise.resolve()
		}
	}

	t(str: string) {
		return this.locale && this.strings[this.locale] && this.strings[this.locale][str]
			? this.strings[this.locale][str]
			: str
	}

}

let vm: TranslationState | null = null

export const TranslationService = {
	install: (vue: any) => {
		if(!vm) {
			vm = new TranslationState()
		}

		vue.loadLang = lang => vm!.setLang(lang)
		vue.prototype.$t = str => vm!.t(str)
	}
}
