import { Vue, Component } from 'vue-property-decorator'
import { config } from './config'

@Component
export class ViewportSizeModule extends Vue {

	width = window.innerWidth
	height = window.innerHeight
	scrollbarWidth = 0
	scrollbarHeight = 0
	aspectRatio = this.width / this.height

	get headerHeight() {
		return Math.ceil(config.vals.globalTheme.headerHeight * ViewportSize.height)
	}
	get bodyHeight() {
		return this.height - this.headerHeight
	}
	get markerSize() {
		return Math.ceil(config.vals.raceTheme.markerHeight * ViewportSize.height)
	}


	private update() {
		// Chrome on iOS needs about 15 miliseconds to adjust the window dimensions.
		setTimeout(() => {
			this.width =  window.innerWidth
			this.height = window.innerHeight
		}, 75)
	}

	private measureScrollBar() {
		const scroll = document.createElement('div')
		scroll.style.visibility = 'hidden'
		scroll.style.width = '100px'
		scroll.style.height = '100px'
		scroll.style.overflow = 'scroll'

		document.body.appendChild(scroll)

		this.scrollbarWidth = scroll.offsetWidth - scroll.clientWidth
		this.scrollbarHeight = scroll.offsetHeight - scroll.clientHeight

		document.body.removeChild(scroll)
	}

	private created() {
		this.measureScrollBar()
		window.addEventListener('resize', this.update)
	}

}

const ViewportSize = new ViewportSizeModule()
export default ViewportSize
