import { cloneDeep, mergeWith, get, set } from 'lodash'
import { Vue, Component } from 'vue-property-decorator'
import { Collection, DeepPartial } from 'shared/types'
import { RemoteIdentify, ClientType } from 'shared/types/config-server'

import { Server } from 'shared/state'
import * as configModules from 'shared/config'
import { ConfigBlob } from 'shared/config/blob'

@Component
export class ConfigState extends Vue {

	id = ''
	vals: ConfigBlob = {} as ConfigBlob
	persist = false

	private serverListener = -1

	private defaults: Collection<any> = {}
	// track which modules are part of the 'theme' so changing to the 'Default' theme only pulls from those defaults
	private themeModules: string[] = []

	async init(env: Collection<any>, id?: string, values?: Collection<any>) {

		// generate new id if necessary
		if(!id) {
			id = this.getIdFromUrl()
		}
		this.id = id

		// try to get persisted config from localstorage
		if(!values && this.persist) {
			const local = localStorage.getItem(id)
			if(local) {
				try {
					values = JSON.parse(local)
				} catch {
					// ignore parse errors, we just won't use the locally stored config
					console.error('Could not parse config values from localstorage')
				}
			}
		}

		// still no values means we pay attention to environment
		if(!values) {
			let theme = {}
			if(env.global && env.global.theme && env.global.theme !== 'Default') {
				try {
					theme = await fetch(`/config/themes/${env.global.theme}.json`)
						.then(res => res.json())
				} catch {
					// ignore network or parse errors, we just won't use the theme
					console.error(`Error reading file /config/themes/${env.global.theme}.json`)
				}
			}
			// env has precedence over theme
			values = Object.assign(theme, env)
		}

		this.set(values)
	}

	// NOTE: caller should handle theme file not found or parse failed errors
	async changeTheme(themeName: string) {
		themeName = themeName.replace(/\.json$/i, '')
		let theme: any = {}

		if(themeName === 'Default') {
			theme = this.themeModules.reduce((dTheme, name) => {
				dTheme[name] = this.defaults[name]
				return dTheme
			}, {})
		} else {
			theme = await (await fetch(`/config/themes/${themeName}.json`)).json()
		}
		theme.global = {}
		theme.global.theme = themeName

		this.set(theme)
	}

	set(patch: DeepPartial<ConfigBlob>, emit = true) {
		this.safeMerge(this.vals, patch)
		if(this.persist) {
			localStorage.setItem(this.id, JSON.stringify(this.vals))
		}
		if(emit && this.vals.global.remoteId) {
			Server.send({ remote_config_change: { id: this.vals.global.remoteId, config: this.vals } })
		}
	}

	getByPath(path: string) {
		return get(this.vals, path)
	}

	setByPath(path: string, val: any) {
		const patch = {}
		set(patch, path, val)
		this.set(patch)
	}

	setRemoteId(id: string, type: ClientType) {
		if(id) {
			// clean up local storage if appropriate
			if(this.persist) {
				// do not delete the stored config if it was already a named display
				if(!this.vals.global.remoteId) {
					localStorage.removeItem(this.id)
				}
				this.setUrlId(id)

				const identify: RemoteIdentify = {
					type,
					id,
					config: this.vals
				}
				Server.send({ remote_identify: identify })
			}

			this.id = id
			this.set({ global: { remoteId: id } })

			if(this.serverListener < 0) {
				this.serverListener = Server.addListener(this.handleMessage)
			}
		} else if(this.serverListener > -1) {
			Server.send({ remote_identify: { id: '', type: '' } })
			this.set({ global: { remoteId: '' } })
			Server.removeListener(this.serverListener)
			this.setUrlId('')
			this.getIdFromUrl()
			this.serverListener = -1
		}
	}

	themeAsJSON() {
		return JSON.stringify(this.getTheme())
	}

	private getTheme() {
		return this.themeModules.reduce((dTheme, name) => {
			dTheme[name] = this.defaults[name]
			return dTheme
		}, {})
	}

	private handleMessage(type: string, packet: any) {
		if(type !== 'remote_config_change' || packet.id !== this.vals.global.remoteId) { return }
		this.set(packet.config, false)
	}

	private created() {
		(this.vals as any) = {}
		Object.keys(configModules).forEach(moduleName => {
			const module = configModules[moduleName]
			const defaults = Object.keys(module.fields).reduce((d, f) => {
				d[f] = module.fields[f].default
				return d
			}, {})
			this.registerModule(moduleName, defaults, module.isTheme)
		})
	}

	// call before init. defaults object should contain all keys so
	// we ensure every required value is present at all times. values
	// from other sources are always merged on top of defaults.
	private registerModule(name: string, defaults: any, theme = false) {
		this.$set(this.vals, name, cloneDeep(defaults))
		this.defaults[name] = defaults
		if(theme) {
			this.themeModules.push(name)
		}
	}

	private getIdFromUrl() {
		const queryString = new URLSearchParams(window.location.search)
		let id = queryString ? queryString.get('id') : undefined

		if(!id) {
			id = Math.random().toString(36).substr(2, 7)
			if(this.persist) {
				this.setUrlId(id)
			}
		}

		return id
	}

	private setUrlId(id: string) {
		window.history.replaceState(null, '', window.location.pathname + '?id=' + id + window.location.hash)
	}

	// merges one object into another without merging arrays.
	// (the source array will overwrite the dest array instead of merging)
	private safeMerge(dest: any, source: any) {
		mergeWith(dest, source, (obj, src) => {
			if(Array.isArray(src) && Array.isArray(obj)) {
				return src
			}
		})
	}

}
