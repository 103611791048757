










import { Vue, Component, Prop } from 'vue-property-decorator'

import VueColor from 'vue-color'

@Component({ components: { 'chrome-picker': VueColor.Chrome } })
export default class ColorControl extends Vue {

	@Prop() label?: string
	@Prop() name!: string
	@Prop() value!: string

	displayPicker = false

	showPicker() {
		document.addEventListener('click', this.documentClick)
		this.displayPicker = true
	}

	hidePicker() {
		document.removeEventListener('click', this.documentClick)
		this.displayPicker = false
	}

	togglePicker() {
		this.displayPicker ? this.hidePicker() : this.showPicker()
	}

	documentClick(e: any) {
		if(!this.$refs.picker) { return }
		const el = (this.$refs.picker as any).$el
		if(el !== e.target && !el.contains(e.target)) {
			this.hidePicker()
		}
	}

	updateValue(c: any) {
		const color = c.rgba.a !== 1
			? `rgba(${c.rgba.r},${c.rgba.g},${c.rgba.b},${c.rgba.a})`
			: c.hex
		this.$emit('input', color)
	}

}
