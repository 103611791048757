






import { Vue, Component, Prop } from 'vue-property-decorator'

import { SelectOptions } from 'shared/types'
import { normalizeSelectOptions } from 'shared/util'

@Component
export default class RadioControl extends Vue {

	@Prop() label?: string
	@Prop() name!: string
	@Prop({ type: [Array, Object] }) options!: SelectOptions
	@Prop() value!: string

	get selectOptions() {
		return normalizeSelectOptions(this.options).map(o => ({
			text: o.label,
			value: o.value
		}))
	}

}
