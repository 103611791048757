import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'

export interface LdbdTheme {
	classLabelColor: string
	rowHeight: number
	highlightColor: string
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		ldbdTheme: LdbdTheme
	}
}

export const ldbdTheme: ConfigModule<LdbdTheme> = {
	page: 'leaderboard',
	panel: 'Theme',
	isTheme: true,
	fields: {
		classLabelColor: {
			default: 'rgba(0,0,0,0.7)',
			control: controls.Color('Class Label Color')
		},
		rowHeight: {
			default: 30,
			control: controls.Number('Row Height')
		},
		highlightColor: {
			default: 'rgba(255, 255, 255, 0.4)',
			control: controls.Color('Highlight Color')
		}
	}
}
