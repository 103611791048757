import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'

export interface LdbdConfig {
	showClasses: boolean
	showLane: boolean
	gapDelay: number
	units: string[]
	performance: string[]
	added: string[]
	sortBy: string
	order: 'asc' | 'desc'
	numSplits: number
	highlight: string[]
	timeSplitUnit: 'split_pace' | 'split_distance'
	allowTwoCol: boolean,
	allowDownload: boolean
	animatedSort: boolean
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		ldbdConfig: LdbdConfig
	}
}

export const ldbdConfig: ConfigModule<LdbdConfig> = {
	page: 'leaderboard',
	panel: 'Options',
	isTheme: false,
	fields: {
		showClasses: {
			default: true,
			control: controls.Checkbox('Show Classes'),
			group: 'Show'
		},
		showLane: {
			default: false,
			control: controls.Checkbox('Show Lane Number'),
			group: 'Show'
		},
		gapDelay: {
			default: 10,
			control: controls.Number('Gap Delay')
		},
		added: {
			default: [ 'splits' ],
			control: controls.Multiselect('Added Columns', {
				Splits: 'splits',
				'Machine type icon': 'active_machine',
				Affiliation: 'affiliation'
			})
		},
		performance: {
			default: [ 'gap', 'meters_behind' ],
			control: controls.Multiselect('Performance', [
				{ label: 'Gap (recommended)', value: 'gap' },
				{ label: 'Units behind leader', value: 'behind' },
			])
		},
		units: {
			default: [ 'pace', 'avg_pace', 'finish' ],
			control: controls.Multiselect('Units to Show', [
				{ label: 'Current Pace', value: 'pace' },
				{ label: 'Avg. Pace (recommended)', value: 'avg_pace' },
				{ label: 'Strokes/Minute', value: 'spm' },
				{ label: 'Heartrate', value: 'heartrate' },
				{ label: 'Finish Time', value: 'finish' }
			])
		},
		sortBy: {
			default: 'position'
		},
		order: {
			default: 'asc'
		},
		numSplits: {
			default: 2,
			control: controls.Number('# of Splits', 1, 1, 5)
		},
		highlight: {
			default: [],
			control: controls.Multiselect('Highlight', [
				{ label: 'Fastest Pace', value: 'pace' },
				{ label: 'Fastest Stroke Rate', value: 'spm' },
				{ label: 'Fastest Splits', value: 'splits' }
			])
		},
		timeSplitUnit: {
			default: 'split_pace',
			control: controls.Radio('Split Unit (Time Race)', [
				{ label: 'Avg. Pace', value: 'split_pace' },
				{ label: 'Distance', value: 'split_distance' }
			])
		},
		allowTwoCol: {
			default: true,
			control: controls.Checkbox('Two Cols if Room'),
			group: 'Display'
		},
		allowDownload: {
			default: true,
			control: controls.Checkbox('Download Upon Race End')
		},
		animatedSort: {
			default: true,
			control: controls.Checkbox('Animated Sort'),
			group: 'Display'
		}
	}
}
