import Vue from 'vue'
import Router from 'vue-router'
import Participant from './components/Participant.vue'

import { displayType } from 'shared/util'
import { config } from './config'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'dashboard',
			component: () => import('./_pen/_LandingPage.vue'),
			meta: {
				title: 'Dashboard'
			}
		},

		
	]
})

router.afterEach((to, from) => {
	if (to.name !== null) {
		config.set({ global: { route: to.name } })
		if (config.vals.global.remoteId) {
			config.setRemoteId(config.vals.global.remoteId, displayType(to.name))
		}
		document.title = to.meta ? to!.meta.title : ''
	}
})

export default router
