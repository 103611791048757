const axios = require('axios').default
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { makeComputedBinding, formatTime } from 'shared/util'
import { CompetitionType, Competition, CompetitionCreationVals, RaceFormType, RaceWithRac, RaceWithoutRac } from '../types/competition'

import { LogBookUser } from './LogBookUser'
import { LogBookRaceItemType } from '../types/racelistitem'

import { RemoteErgStatusLevels } from 'shared/types/erg'
import { Instance, CompetitionInstance } from 'shared/types/logBook'
import { getCookie } from 'shared/util/cookie'
import { RaceDefinition, RaceDefinitionWrapper } from 'shared/types/erg'

// tslint:disable-next-line:class-name
interface resultRecord {
   raceId: number,
   result: any
}

@Component
class CompetitionsModule extends Vue {
	competitions: Competition[] = []
	currentCompetitionCode = ''
	currentCompetition!: CompetitionType
	defaultInstance: Instance = {
		status: '',
		ergrace_url: '',
		ergrace_port: 0,
		holding_pen_port: 0,
		holding_pen_url: '',
		display_socket: 0,
		display_url: ''
	}
	currentInstance: Instance = this.defaultInstance
	runningRaces: number[] = []
	results: resultRecord[] = []
	instances: Instance[] = []
	@Prop() loading!: boolean

	apiEndpoint = process.env.VUE_APP_API_ENDPOINT

	logout() {
		LogBookUser.logout()
		this.competitions = []
		this.currentCompetitionCode = ''
		this.currentInstance = this.defaultInstance
		this.runningRaces = []
		this.instances = []
		location.replace('/')
	}

	async createCompetition(competition: Competition | CompetitionCreationVals): Promise<CompetitionType> {
		return new Promise((resolve, reject) => {
			LogBookUser.createCompetition(competition).then(async (result) => {
				if (result.code) {
					this.competitions.unshift(result)
					await this.createOrRenewInstance(result.code)

					return result
				} else {
					return result
				}
			})
		})
	}

	async createOrRenewInstance(code: string) {
		return await axios
			.get(`${this.apiEndpoint}/instance/${code}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then(async (result) => {
				if (result.success && result.data) {
					this.currentInstance = result.data
					this.currentInstance.competition_code = code
					this.getInstances(code)
				}
				return
			})
	}
	async deleteInstance(code: string): Promise<boolean> {
		return await axios
			.get(`${this.apiEndpoint}/instance/delete/${code}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then(async (result) => {
				return result.data.success
			})
			.catch(err => false)
	}

	async getCompetitions() {
		const result = await LogBookUser.getCompetitions()
		this.competitions = result
		return result
	}
	async getCompetitionsWithInstanceInfo() {
		const result = await LogBookUser.getCompetitions()
		result.forEach(async c => {
			this.instances = []
			const i = await this.getInstances(c.code)
			this.instances.push(i)
		})
		this.competitions = result
		return result
	}

	async getInstances(code: string): Promise<Instance> {
		const i = LogBookUser.getInstances(code)
		i.then(async (response: any) => {
			if (response && response.data) {
				// array of instance
				this.currentInstance = response[0]
				this.currentInstance.competition_code = code
				await this.createOrRenewInstance(code)
			} else {
				this.currentInstance = response
				this.currentInstance.competition_code = code
				await this.createOrRenewInstance(code)
			}
		}).catch(async (error) => {
			await this.createOrRenewInstance(code)
			return error.status_code === 404 ? Promise.resolve({}) : Promise.reject(error)
		})
		return i
	}

	async getCompetition(code: string): Promise<Competition> {
		this.currentCompetitionCode = code

		// set the current Instance at the same time

		const result: Competition = await LogBookUser.getCompetition(code)

		this.currentCompetition = Object.assign({}, result)

		result.races.data.forEach(r => {
			let myRace = this.currentCompetition.races.data.find(cr => cr.id === r.id)
			if(!myRace) {
				this.currentCompetition.races.data.push(r)
			} else {
				myRace = r
			}
		})
		await this.getInstances(code)

		return this.currentCompetition
	}
	async deleteCompetition(code: string): Promise<boolean> {
		if(await this.deleteInstance(code))  {
			if(await LogBookUser.deleteCompetition(code)) {
				return true
			}
			return false
		} else {
			return false
		}

	}
	async updateCompetition(competition: CompetitionType): Promise<CompetitionType> {
		const c = LogBookUser.updateCompetition(competition)
		c.then((success) => {
			if (success) {
				const i = this.competitions.findIndex((c) => {
					return c.code === competition.code
				})
				if (i !== -1) {
					this.competitions[i] = competition
				}
			}
		})
		return c
	}
	async getRaces(): Promise<LogBookRaceItemType[]> {
		const result = await LogBookUser.getRaces(this.currentCompetitionCode, 'races,races.rac')
		return result
	}
	async getRace(raceId: number, include?: string): Promise<RaceWithRac | RaceWithoutRac> {
		return await LogBookUser.getRace(raceId, include)
	}
	async deleteRace(raceId: number) {
		const result = await LogBookUser.deleteRace(raceId)
		if(result && result.data && result.data.message && result.data.message === 'Race deleted successfully') {
			const index = this.currentCompetition.races.data.findIndex(r => r.id === raceId)
			if(index !== -1) {
				this.currentCompetition.races.data.splice(index, 1)
			}
		}
		return result
	}

	async saveRace(race: any): Promise<any> {
		const code = this.currentCompetition.code

		// convert the race.rac.data format for saving
		// race.rac_file.race_definition ...


		if(!race.projected) { delete(race.projected) }
		if(!race.scheduled) { delete(race.scheduled) }

		race.rac_file = race.rac.data

		//console.log('race pre-save: ', race)
		const result = await LogBookUser.saveRace(code, race)

		if (result && result.id) {
			//console.log('race result: ', result)
			return result
		} else {
			return Promise.reject('did not save')
		}
	}

	async getRacFile(raceId: number): Promise<any> {
		return await LogBookUser.getRacFile(this.currentCompetitionCode)
	}
	async saveRacFile(raceId: number, race: RaceDefinition): Promise<RaceDefinitionWrapper> {

		return await LogBookUser.saveRacFile(raceId, race)
	}


	@Watch('currentCompetitions.rac.data.races', { deep: true, immediate: true })
	competitionsChanged(newVal) {
		// console.log('current race changed', newVal)
	}
	async getResults(raceId: number ): Promise<any> {
		const r =  await LogBookUser.getResults(raceId)
		if(r) { return r }
		return
	}

}

export const Competitions = new CompetitionsModule()
export const CompetitionsState = makeComputedBinding(Competitions)
