import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'

export interface RaceConfig {
	scale: number
	autoSort: boolean
	sortAfter: number
	sortAfterCal: number
	sortSmallRaces: boolean
	highlightParticipant: boolean
	startAt: number
	laneNumbers: boolean
	affiliationCode: boolean
	pace: 'pace' | 'avg_pace' | ''
	gap: 'gap' | 'behind' | ''
	showPaceboat: 'not shown' | 'shown' | 'marker' | 'marker with line' | 'circle' | 'circle with line'
	gapAfter: number
	gapUntil: number
	gapAfterCal: number
	gapUntilCal: number
	classLabels: boolean
	displayClasses: string[]
	easingCurve: string
	placeAfter: number
	placeAfterCal: number,
	disableTenRowScalingMode: boolean,
	biathlonVitalScaling: boolean
	autoScale: boolean
	autoScalePct: number
	autoScaleDelay: number
	zoomInTrigger: number
	zoomOutTrigger: number
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		raceConfig: RaceConfig
	}
}

export const raceConfig: ConfigModule<RaceConfig> = {
	page: 'race',
	panel: 'Options',
	isTheme: false,
	fields: {
		scale: {
			default: 0.5,
			control: {
				component: 'vertical-slider',
				label: 'Field of View',
				options: {
					min: 0.01,
					step: 0.01,
					max: 1,
					topLabel: '1%',
					bottomLabel: '100%'
				}
			}
		},
		autoSort: {
			default: true
		},
		sortAfter: {
			default: 100
		},
		sortAfterCal: {
			default: 10
		},
		classLabels: {
			default: false,
			control: controls.Checkbox('Class Labels'),
			group: 'Options',
			hideDuringRace: true
		},
		laneNumbers: {
			default: true,
			control: controls.Checkbox('Lane Numbers'),
			group: 'Options'
		},
		affiliationCode: {
			default: true,
			control: controls.Checkbox('Affiliation Code'),
			group: 'Options'
		},
		sortSmallRaces: {
			default: true,
			control: controls.Checkbox('Sort by Place'),
			group: 'Options'
		},
		disableTenRowScalingMode: {
			default: false,
			control: controls.Checkbox('Disable 10 Row Scaling'),
			group: 'Options',
			private: true,
			hideDuringRace: true
		},
		biathlonVitalScaling: {
			default: false,
			control: controls.Checkbox('Biathlon Scaling'),
			group: 'Options',
			private: true,
			hideDuringRace: true
		},
		highlightParticipant: {
			default: true,
			control: controls.Checkbox('Highlight Participant'),
			group: 'Options',
			private: true
		},
		startAt: {
			default: 1,
			control: controls.Number('Start At', 1),
			hideDuringRace: true
		},
		pace: {
			default: 'pace',
			control: controls.Radio('Pace Display', {
				'Instantaneous Pace': 'pace',
				'Average Pace': 'avg_pace'
			}),
			hideDuringRace: true
		},
		gap: {
			default: 'gap',
			control: controls.Radio('Gap Display', {
				Gap: 'gap',
				'Units Behind': 'behind'
			}),
			private: true,
			hideDuringRace: true
		},
		showPaceboat: {
			default: 'marker',
			control: controls.Radio('Show Paceboat', {
				'Not Shown': 'not shown',
				'Shown in Lane': 'shown',
				'As Marker': 'marker',
				'As Marker with Line': 'marker with line',
				'As Circle': 'circle',
				'As Circle with Line': 'circle with line'
			}),
			hideDuringRace: true,
			private: true
		},
		gapAfter: {
			default: 100
		},
		gapUntil: {
			default: 0
		},
		gapAfterCal: {
			default: 0
		},
		gapUntilCal: {
			default: 0
		},
		displayClasses: {
			default: [],
			control: { component: 'display-classes' },
			hideDuringRace: true
		},
		easingCurve: {
			default: 'Square'
		},
		placeAfter: {
			default: 100
		},
		placeAfterCal: {
			default: 10
		},
		autoScale: {
			default: true,
			control: controls.Checkbox('Auto Scale'),
			group: 'Auto Scale'
		},
		autoScalePct: {
			default: 80,
			control: controls.Number('Track Top %'),
			group: 'Auto Scale'
		},
		autoScaleDelay: {
			default: 10,
			control: controls.Number('Delay (sec)'),
			group: 'Auto Scale'
		},
		zoomInTrigger: {
			default: 1
		},
		zoomOutTrigger: {
			default: 1
		}
	}
}
