import { Vue, Component } from 'vue-property-decorator'

import { makeComputedBinding } from 'shared/util'
import { RemoteIdentify, ClientType } from 'shared/types/config-server'
import { ServerPen, ConfigState } from 'shared/state'
import { RemoteErgPen } from 'shared/state'
import { RemoteErgRaceStatus } from 'shared/types/erg/index'

export interface RemoteScreen {
	type: ClientType
	config: ConfigState
	connected: boolean
}

export interface RemoteControlService {
	screens: Record<string, RemoteScreen>
	screenOptions: string[]

	removeScreen: (screen: string) => void
	remoteErgAction: (action: string, config?: any) => void
	lastRemoteAction: string
}

@Component
class RemoteControlClassPen extends Vue implements RemoteControlService {
	screens: Record<string, RemoteScreen> = {}
	screenOptions: string[] = []

	public lastRemoteAction: string = ''

	removeScreen(screen: string) {
		delete this.screens[screen]
		this.screenOptions.splice(this.screenOptions.indexOf(screen), 1)
		this.screenOptions = [...this.screenOptions]
	}

	remoteErgAction(action: string, config: any) {
		const message: any = { action }
		if (config) {
			message.config = config
		}
		ServerPen.send({
			remote_ergrace_action: message
		})
		this.lastRemoteAction = action
	}

	private handle_remote_ergrace_state(status: RemoteErgRaceStatus) {
		RemoteErgPen.setStatus(status)
	}

	private handle_remote_client_list(list: RemoteIdentify[]) {
		Object.keys(this.screens).forEach((screen) => {
			this.screens[screen].connected = false
		})

		list.filter((display) => display.id && ['race', 'leaderboard', 'global'].includes(display.type)).forEach(
			(display) => {
				if (this.screens[display.id!]) {
					// already existing
					this.screens[display.id!].config.set(display.config!, false)
					this.screens[display.id!].connected = true
					this.screens[display.id!].type = display.type
				} else {
					// newly connected
					const config = new ConfigState()
					config.init(this.$root.$data.env, display.id, display.config)
					config.setRemoteId(display.id!, display.type)
					this.screens[display.id!] = {
						type: display.type,
						config,
						connected: true
					}
					this.screenOptions.push(display.id!)
				}
			}
		)
		this.screens = { ...this.screens }
		this.screenOptions = [...this.screenOptions]
	}

	private created() {
		this.$nextTick(() => {
			ServerPen.addListener((type, packet) => {
				if (typeof this['handle_' + type] === 'function') {
					this['handle_' + type](packet)
				}
			})
		})
	}
}

export const RemoteControlPen: RemoteControlService = new RemoteControlClassPen()
export const RemoteStatePen = makeComputedBinding(RemoteControlPen)
