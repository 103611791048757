import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'
import { ServerModule, ConnectionStatus } from 'shared/state'
import { memoryStatusType, diskStatusType, cpuStatusType } from 'shared/types'
import { bus } from 'shared/state/Bus'
import { getTime } from 'shared/util/formatTime'

export interface User {
	first_name: string
	last_name: string
	username: string
	last_connected: string
	id: number
}

@Component
export class MonitorClass extends Vue  {
	@Prop() Server!: ServerModule
	public memory_status!: memoryStatusType 
	public disk_status!: diskStatusType
	public cpu_status!: cpuStatusType

	server = this.Server
	store: any = []
	users: User[] = []
	version: string = ''
	messages: any[] = []
	settings: any = {}

	send(message) {
		this.server.send(message)
		if (message.action === 'get_store') return
		message.direction = 'sent'
		message.time = getTime(new Date())
		if (message?.detail?.competition) {
			message.competition = message.detail.competition
		}
		this.messages.push(message)
	}
	

	private handle_memory(memory_status:memoryStatusType) {
		this.memory_status = memory_status
		this.$emit('memory_status', memory_status)
		bus.$emit('memory_status', memory_status, this.server.wsUrl)
	}

	private handle_disk(disk_status:diskStatusType) {
		// console.log('in disk_status handler', disk_status)
		this.disk_status = disk_status
		//this.$emit('disk_status', disk_status)
		bus.$emit('disk_status', disk_status, this.server.wsUrl)
	}

	private handle_cpu(cpu_status:cpuStatusType) {
		this.cpu_status = cpu_status
		//this.$emit('cpu_status', cpu_status)
		bus.$emit('cpu_status', cpu_status, this.server.wsUrl)

	}
	private handle_notify(message: any) {
		// TODO: handle messages from race organizers
	}
	private handle_got_store(store: any) {
		this.store = store
	}

	private handle_got_users(users: any) {
		this.users = users
	}

	private handle_got_settings(settings: any) {
		this.settings = settings.settings
		bus.$emit('got_settings', this.settings)
	}

	private handle_monitor_subscribed(info: any) {
		if (info?.version) {
			this.version = info.version
			return
		}
		this.version = '<1.31.0'
	}

	private created() {
			this.server.addListener((type, packet) => {
				if (typeof this['handle_' + type] === 'function') {
					this['handle_' + type](packet)
				}
			})
	}
	
	@Watch('Server.state', { immediate: true }) 
	serverChanged(newVal:ConnectionStatus) {
		if (newVal === 'connected') {
			this.server.send({ action: "subscribe_monitor" })
			this.server.send({ action: "settings" })
		}
	}
}
