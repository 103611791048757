






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CheckboxControl extends Vue {

	@Prop() label!: string
	@Prop() value!: boolean

}
