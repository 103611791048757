import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'

export interface RaceTheme {
	classColors: string[]
	nameColor: string
	scoreColor: string
	columnGutter: number
	gapColor: string
	markerHeight: number
	markerLineColor: string
	paceBoatColor: string
	paceMarkerOutlineColor: string
	participantHighlightColor: string
	markerLineWidth: number
	moreColor: string
	finishedRacerBorderHeight: number
	hybridBorderHeight: number
	vitalPadding: number
	vitalWidths: {
		place: number, name: number, lane: number, affiliation: number, gap: number, pace: number
	},
	rowHeightSmScale: number,
	rowHeightLgScale: number
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		raceTheme: RaceTheme
	}
}

export const raceTheme: ConfigModule<RaceTheme> = {
	page: 'race',
	panel: 'Theme',
	isTheme: true,
	fields: {
		classColors: {
			default: [
				'#FFF100', '#B1A0C7', '#87C540',
				'#F36E29', '#45B9FF', '#FF45E5',
				'#73FFE1', '#C79E33', '#BEBFC1',
				'#FFFFFF', '#FFF100', '#B1A0C7',
				'#87C540', '#F36E29', '#45B9FF',
				'#FF45E5', '#73FFE1', '#C79E33',
				'#BEBFC1', '#FFFFFF', '#FFF100',
				'#B1A0C7', '#87C540', '#F36E29',
				'#45B9FF', '#FF45E5', '#73FFE1',
				'#C79E33', '#BEBFC1', '#FFFFFF',
				'#FFF100', '#B1A0C7', '#87C540',
				'#F36E29', '#45B9FF', '#FF45E5',
				'#73FFE1', '#C79E33', '#BEBFC1',
				'#FFFFFF'
			]
		},
		nameColor: {
			default: 'white',
			control: controls.Color('Name Color')
		},
		scoreColor: {
			default: 'white',
			control: controls.Color('Score Color')
		},
		columnGutter: {
			default: 15,
			control: controls.Number('Column Gutter')
		},
		gapColor: {
			default: 'white',
			control: controls.Color('Gap Color')
		},
		markerHeight: {
			default: 0.0324,
			control: controls.Number('Marker Height %', 0.001, 0.0001, 1)
		},
		markerLineColor: {
			default: '#b4b4b4',
			control: controls.Color('Meter Line Color')
		},
		markerLineWidth: {
			default: 2,
			control: controls.Number('Marker Line Width')
		},
		paceBoatColor: {
			default: 'red',
			control: controls.Color('Pace Boat Color')
		},
		paceMarkerOutlineColor: {
			default: 'white',
		},
		participantHighlightColor: {
			default: 'yellow',
			control: controls.Color('Participant Highlight Color')
		},
		moreColor: {
			default: 'white'
		},
		finishedRacerBorderHeight: {
			default: 4
		},
		hybridBorderHeight: {
			default: 8
		},
		vitalPadding: {
			default: 30
		},
		vitalWidths: {
		  default: {
			place: 0.82,
			name: 4,
			lane: 1.3,
			affiliation: 1.2,
			gap: 1.73,
			pace: 2.46
		  }
		},
		rowHeightSmScale: {
			default: 0.55,
			control: controls.Number('Row Small Scale', 0.05, 0.05, 0.55)
		},
		rowHeightLgScale: {
			default: 1,
			control: controls.Number('Row Large Scale', 0.55, 0.05, 1)
		}
	}
}
