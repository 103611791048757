import { ConfigState } from 'shared/state'
import { makeComputedBinding } from 'shared/util'

import './config.scss'

import Checkbox from 'display/components/controls/Checkbox.vue'
// import DisplayClasses from 'display/components/controls/DisplayClasses.vue'
import Multiselect from 'display/components/controls/Multiselect.vue'
import Number from 'display/components/controls/Number.vue'
import Radio from 'display/components/controls/Radio.vue'
import RemoteId from 'display/components/controls/RemoteId.vue'
import Select from 'display/components/controls/Select.vue'
import Text from 'display/components/controls/Text.vue'
import TestAudio from 'display/components/controls/TestAudio.vue'
// import ThemeSelect from 'display/components/controls/ThemeSelect.vue'

import { ControlRegistry } from 'shared/components/controls'
ControlRegistry.checkbox = Checkbox
// ControlRegistry['display-classes'] = DisplayClasses
ControlRegistry.multiselect = Multiselect
ControlRegistry.number = Number
ControlRegistry.radio = Radio
ControlRegistry.remoteId = RemoteId
ControlRegistry.select = Select
ControlRegistry.text = Text
ControlRegistry.testAudio = TestAudio
// ControlRegistry.themeSelect = ThemeSelect

import { global } from 'shared/config'
global.fields.remoteId.control = { component: 'remoteId', label: 'Remote ID' }

export const config = new ConfigState()
config.persist = true

export const GlobalConfig = makeComputedBinding(config.vals.global)
export const GlobalTheme = makeComputedBinding(config.vals.globalTheme)
// export const RaceConfig = makeComputedBinding(config.vals.raceConfig)
// export const RaceTheme = makeComputedBinding(config.vals.raceTheme)
// export const LdbdConfig = makeComputedBinding(config.vals.ldbdConfig)
// export const LdbdTheme = makeComputedBinding(config.vals.ldbdTheme)
