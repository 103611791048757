import { Vue, Component } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'

export type ConnectionStatusPen = 'unconnected' | 'connected' | 'disconnected'

@Component
export class ServerModulePen extends Vue {
	wsUrl = 'ws://admin.ergrace.com/ws:443'
	hostname = 'ergrace.com'
	state: ConnectionStatusPen = 'unconnected'
	ws: WebSocket | null = null
	packetListeners: Array<((channel: string, packet: any) => void) | undefined> = []

	setWsUrl(wsUrl) {
		if (!wsUrl || wsUrl === 'ws://localhost:443') {
			return
		}
		try {
			const url = new URL(wsUrl)
			this.wsUrl = wsUrl
			this.hostname = url.hostname
		} catch (e) {
			console.error('Invalid websocket url received: ' + wsUrl)
		}
	}

	connect() {
		return new Promise((resolve, reject) => {
			this.state = 'unconnected'

			try {
				// console.log(this.ws)
				this.ws = new WebSocket(this.wsUrl)
			} catch (err) {
				return reject(err)
			}

			this.ws.addEventListener('open', () => {
				this.state = 'connected'
				resolve(null)
			})

			this.ws.addEventListener('close', () => {
				this.state = 'disconnected'
			})

			this.ws.addEventListener('message', (msg) => {
				const e = document.getElementById('penOut')
				if(e) {
					e.innerText += JSON.stringify(msg)
				}

				const data = JSON.parse(msg.data)
				// console.log(data)
				Object.keys(data).forEach((channel) => {
					this.packetListeners
						.filter((listener) => listener)
						.forEach((listener) => listener!(channel, data[channel]))
				})
			})
		})
	}

	disconnect() {
		this.ws?.close()
	}

	send(message: any) {
		// document.getElementById('penIn')!.innerText! += JSON.stringify(message)
		if (!this.ws || this.ws.readyState !== this.ws.OPEN) {
			return
		}
		this.ws.send(JSON.stringify(message))
	}

	addListener(listener: (type: string, packet: any) => void): number {
		this.packetListeners.push(listener)
		return this.packetListeners.length - 1
	}

	removeListener(id: number) {
		this.packetListeners[id] = undefined
	}
}

export const ServerPen = new ServerModulePen()
export const ServerStatePen = makeComputedBinding(ServerPen)
