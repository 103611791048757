

















import { Vue, Component, Prop } from 'vue-property-decorator'

interface SliderOptions {
	topLabel: string
	bottomLabel: string
	min: number | null
	max: number | null
	step: number | null
}

@Component
export default class VerticalSlider extends Vue {

	@Prop() label?: string
	@Prop() name!: string
	@Prop() value!: number
	@Prop() options!: SliderOptions

}
