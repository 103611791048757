import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'
import { defaultLanguages } from 'shared/services/languages'

export interface GlobalConfig {
	websocketUri: string
	googleAnalytics: string
	lang: string
	theme: string
	devMode: boolean
	playAudio: boolean
	disableAlerts: boolean
	hideHud: boolean
	paceraceLockScore: boolean
	remoteId: string
	logBookId?: string
	route: string
	testAudioTrigger: number
	recoverTime: number
	athleteStatus: string
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		global: GlobalConfig
	}
}

export const global: ConfigModule<GlobalConfig> = {
	page: '',
	panel: 'Global',
	isTheme: false,
	fields: {
		websocketUri: {
			default: 'ws://localhost:443'
		},
		googleAnalytics: {
			default: ''
		},
		lang: {
			default: 'en-US',
			control: controls.Select('Language', defaultLanguages),
			hideDuringRace: true
		},
		devMode: {
			default: false,
			private: true,
			control: controls.Checkbox('Development Mode')
		},
		playAudio: {
			default: true,
			control: controls.Checkbox('Play Audio')
		},
		disableAlerts: {
			default: false,
			control: controls.Checkbox('Disable Race Start Banners'),
			hideDuringRace: true
		},
		hideHud: {
			default: false,
			control: controls.Checkbox('Hide Clock & 1st Place Units'),
			private: true
		},
		paceraceLockScore: {
			default: true,
			control: controls.Checkbox('Lock Score During Paceboat Race')
		},
		theme: {
			default: 'Default',
			control: { component: 'themeSelect', label: 'Theme' },
			hideDuringRace: true
		},
		remoteId: {
			default: '',
			private: true
		},
		route: {
			default: '',
			control: controls.Select('Display', {
				Home: 'home',
				Hybrid: 'race',
				Scroll: 'scroll',
				Leaderboard: 'leaderboard',
				Summary: 'summary'
			}),
			private: false
		},
		testAudioTrigger: {
			default: 0,
			control: { component: 'testAudio', label: 'Test Audio' },
			hideDuringRace: true
		},
		recoverTime: {
			default: 30,
			control: controls.Number('Recover Wait Time (seconds)', 3, 1, 60),
			hideDuringRace: false
		},
		athleteStatus: {
			default: '',
			control: { component: 'text', label: 'Athlete Status' },
			hideDuringRace: false
		}
	}
}
