import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Icons
import 'vue-awesome/icons/cogs'
import 'vue-awesome/icons/volume-off'
import 'vue-awesome/icons/volume-up'
import 'vue-awesome/icons/sign-in-alt'
import 'vue-awesome/icons/sign-out-alt'
import 'vue-awesome/icons/user-times'
import 'vue-awesome/icons/redo'
import 'vue-awesome/icons/robot'
import 'vue-awesome/icons/power-off'
import 'vue-awesome/icons/comment'
import 'vue-awesome/icons/chart-line'
import 'vue-awesome/icons/cog'
import 'vue-awesome/icons/question-circle'

import Icon from 'vue-awesome/components/Icon.vue'
Vue.component('icon', Icon)

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/custom.scss'
Vue.use(BootstrapVue)

// Plugins
import { TranslationService, KeybindingsService } from 'shared/services'
// import { AnimationService } from 'display/services'
Vue.use(TranslationService)
Vue.use(KeybindingsService)
// Vue.use(AnimationService)

Vue.config.productionTip = false

import { Server } from 'shared/state'
import init from 'shared/init'
import { config } from 'display/config'


init(Vue).then(env => {
	config.init(env)
	new Vue({
		data: { env },
		router,
		render: (h) => h(App),
	}).$mount('#app')
})
