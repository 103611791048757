import { config } from 'display/config'
import ViewportSize from 'display/ViewportSize'

export default function globalCssVars() {
	const globalTheme = config.vals.globalTheme

	return {
		'--global-font': globalTheme.font,
		'--col-heading-bg': globalTheme.colHeadingBg,
		'--col-heading-text': globalTheme.colHeadingText,
		'--finish-color-1': globalTheme.finishColor1,
		'--finish-color-2': globalTheme.finishColor2,
		'--lane-color-1': globalTheme.laneColor1,
		'--lane-color-2': globalTheme.laneColor2,
		'--place-bg-color': globalTheme.placeBgColor,
		'--place-text-color': globalTheme.placeTextColor,
		'--gutter-color': globalTheme.gutterColor,
		'--header-height': ViewportSize.headerHeight + 'px',
		'--marker-size': ViewportSize.markerSize + 'px',
		'--dark-blue': '#003A63',
		'--light-blue': '#0567C1',
		'--ck-border-radius': '4px'

	}
}
