














import { Vue, Component, Prop } from 'vue-property-decorator'

import { ConfigState } from 'shared/state'
import { ConfigControl } from 'shared/types'
import { slugify } from 'shared/util'

import { ControlRegistry } from 'shared/components/controls'

@Component
export default class ConfigPanel extends Vue {

	@Prop() group!: boolean
	@Prop() label!: string
	@Prop() options!: ConfigControl[]
	@Prop() config!: ConfigState

	getControlComponent(name: string) {
		return name === 'controlGroup' ? ConfigPanel : ControlRegistry[name]
	}

	getFieldName(path: string) {
		const segments = path.split('.')
		return segments[segments.length - 1]
	}

	get className() {
		return `config-panel ${slugify(this.label)} ${this.group ? 'group' : ''}`
	}

}
